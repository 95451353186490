import { CurrentUrlParams, ParamsModel } from '@src/app/models/params-model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { DecimalPipe } from '@angular/common';
import { Params } from '@angular/router';

/**
 * Template datetime format using to show on UI
 * This format includes time zone abbreviations.
 * Abbreviations tend to be based on English. Sometimes there is a valid abbreviation in another language, and it may be confusing which to use.
 * Some time zones may never actually use the abbreviation in the region where the time zone is observed.
 * See also, the list of Time Zone Abbreviations on Wikipedia (https://en.wikipedia.org/wiki/List_of_time_zone_abbreviations)
 */
export const FORMAT_DATETIME: string = 'MM/DD/YYYY HH:mm:ss z';
export const FORMAT_CHART_DATETIME: string = 'MMM DD, YYYY';
/**
 * a fixed, default value (GTC is set to 1.5 by default)
 * https://zonarsystems.atlassian.net/wiki/spaces/GTCX/pages/137815458028/Idle+Table+Component+Breakdown
 */

export const isLocalDevEnv = () => {
  // in order for unit tests (ran from localhost:9876) to pass, they need to be excluded from 'local'
  return (
    window?.location?.origin?.includes('localhost:4200') ||
    window?.location?.origin?.includes('local.dev')
  );
};

export const ASSET_PROPERTIES_FILTER_KEYS = [
  'asset_type',
  'asset_year',
  'asset_make',
  'asset_model',
  'asset_body_class',
  'asset_engine_config',
  'asset_fuel_type',
  'asset_gvwr',
  'asset_custom_properties'
];

// Source: https://stackoverflow.com/a/53943624
export function repeatTimes(times: number) {
  return {
    [Symbol.iterator]: function* () {
      for (let i = 0; i < times; i++, yield) {}
    }
  };
}

export const formatFromISODateTime = (
  dayTime: string,
  format = FORMAT_DATETIME
): string => {
  return formatDateTime(dayTime, format);
};

export const formatDateTime = (dayTime: string, format: string): string => {
  dayjs.extend(advancedFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs(dayTime).format(format || FORMAT_DATETIME);
};

export function isArrayEquals(arr1: Array<any>, arr2: Array<any>) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

/**
 * Compare 2 values, only support primitive type, string, array
 * @param a
 * @param b
 */
export function equals(a, b) {
  if (typeof a !== typeof b) {
    return false;
  } else if (Array.isArray(a) && Array.isArray(b)) {
    return isArrayEquals(a, b);
  } else {
    return a == b;
  }
}

export function isParamsChanged(previous: Params, current: Params) {
  if (previous && current) {
    return (
      equals(current.company_id, previous.company_id) &&
      equals(current.start_time, previous.start_time) &&
      equals(current.end_time, previous.end_time) &&
      equals(current.asset_id, previous.asset_id) &&
      equals(current.division_id, previous.division_id) &&
      equals(current.speed_class, previous.speed_class) &&
      equals(current.asset_year, previous.asset_year) &&
      equals(current.asset_type, previous.asset_type) &&
      equals(current.asset_make, previous.asset_make) &&
      equals(current.asset_model, previous.asset_model) &&
      equals(current.asset_body_class, previous.asset_body_class) &&
      equals(current.asset_engine_config, previous.asset_engine_config) &&
      equals(current.asset_fuel_type, previous.asset_fuel_type) &&
      equals(current.asset_gvwr, previous.asset_gvwr) &&
      equals(
        current.asset_custom_properties,
        previous.asset_custom_properties
      ) &&
      equals(current.severity, previous.severity) &&
      equals(current.driver_id, previous.driver_id) &&
      equals(current.driver_division_id, previous.driver_division_id)
    );
  }
  return false;
}

export function isPaginationParamsChanged(previous: Params, current: Params) {
  return (
    previous?.sort_by != current?.sort_by ||
    previous?.sort_order != current?.sort_order ||
    previous?.page != current?.page ||
    (previous?.per_page && previous?.per_page != current.per_page)
  );
}

export function isAllParamsChanged(previous: Params, current: Params) {
  return (
    isParamsChanged(previous, current) &&
    !isPaginationParamsChanged(previous, current)
  );
}

export function convertDuration(
  value: string | number,
  decimalPipe: DecimalPipe
): string {
  const hrs = Number(value);
  const hh = decimalPipe.transform(Math.trunc(hrs), '2.0-0');
  const mm = ('0' + Math.trunc((hrs * 60) % 60)).slice(-2);
  const ss = ('0' + Math.trunc((hrs * 3600) % 60)).slice(-2);

  return `${hh}h ${mm}m ${ss}s`;
}

export function getLocalTimeZone(): string {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs.tz.guess();
}

export function convertMilimeterPerSecToMilesPerHour(value: number): number {
  return value * 0.00223693629;
}

export function removeInvalidSortParams(
  params: Params,
  sortableColumns: string[]
): Params {
  const newParams = { ...params };
  if (newParams.sort_by && !sortableColumns.includes(newParams.sort_by)) {
    delete newParams.sort_by;
    delete newParams.sort_order;
  }
  return newParams;
}
